import Business from 'vita-business-node';

import {returnError} from '../../utils';

const getList = async (params) => {
    try {
        const {pagination, filter = {}} = params;
        const {page, perPage} = pagination;

        const filters = {
            page,
            count: perPage,
            ...filter,
        };

        let response = await Business.transactions().get(filters);

        return Promise.resolve({
            data: response.data,
            total: response.total,
        });
    } catch (error) {
        return returnError(error);
    }
};

const getOne = async (params) => {
    try {
        const {id} = params;
        let transaction = await Business.transactions(id).get();

        return Promise.resolve({data: transaction});
    } catch (error) {
        return returnError(error);
    }
};

const create = async (params) => {
    try {
        const {data} = params;
        const {transactions_type, wallet} = data;
        const transactionWallet = Business.wallets(wallet);

        delete data["transactions_type"];
        delete data["wallet"];

        let response = null;
        switch (transactions_type) {
            case 'recharge':
                response = await transactionWallet.recharge(data);
                const {redirect_url} = response;
                window.location = redirect_url;

                break;
            case 'withdrawal':
                response = await transactionWallet.withdrawal(data);
                break;
            case 'sent':
                response = await transactionWallet.send(data);
                break;
            case 'purchase':
                response = await transactionWallet.purchase(data);
                break;
            case 'vita_sent':
                response = await transactionWallet.vitaSend(data);
                break;
            default: {
                return Promise.reject(new Error('Opration not supported'));
            }
        }

        return Promise.resolve({data: response});
    } catch (error) {
        return returnError(error);
    }
};

const dataProvider = {
    getList,
    getOne,
    create,
};

export default dataProvider;
