import List from './List';
import Show from './Show';

const resource = {
    name: 'requests',
    list: List,
    show: Show,
};

export default resource;
