import Business from 'vita-business-node';

const getRules = () => {
    return Business
        .rulesProvider
        .getWithdrawalRules();
};

const service = {
    getRules,
};

export default service;