import React from 'react';
import {List, Datagrid, TextField, ArrayField, FunctionField} from 'react-admin';

const Rules = (props) => (
    <List
        {...props}
        title="Lista de reglas de retiros"
    >
        <Datagrid>
            <TextField
                label="País"
                source="id"
            />
            <ArrayField source="fields">
                <Datagrid>
                    <TextField
                        label="Tipo"
                        source="type"
                    />
                    <TextField
                        label="Nombre"
                        source="key"
                    />
                    <TextField
                        label="Min"
                        source="min"
                    />
                    <TextField
                        label="Max"
                        source="max"
                    />
                    <FunctionField
                        label="Visible"
                        render={record => record.when ? `Cuando ${record.when.key} sea igual a ${record.when.value}` : ''}
                    />
                    <ArrayField source="options">
                        <Datagrid>
                            <TextField
                                label="Label"
                                source="label"
                            />
                            <TextField
                                label="Valor"
                                source="value"
                            />
                        </Datagrid>
                    </ArrayField>
                </Datagrid>
            </ArrayField>
        </Datagrid>
    </List>
);

export default Rules;