import React from 'react';
import { List, Datagrid, TextField, DateField, BooleanField, ShowButton } from 'react-admin';

const Wallets = (props) => (
    <List
        {...props}
        title="Lista de wallets"
    >
        <Datagrid>
            <TextField source="uuid" />
            <DateField source="created_at" />
            <TextField label="balance CLP" source="balances.clp" />
            <TextField label="balance USD" source="balances.usd" />
            <TextField label="balance COP" source="balances.cop" />
            <TextField label="balance ARS" source="balances.ars" />
            <TextField label="balance MXN" source="balances.mxn" />
            <TextField label="balance BTC" source="balances.btc" />
            <TextField label="balance USDT" source="balances.usdt" />
            <TextField label="balance USDC" source="balances.usdc" />
            <BooleanField source="is_master" />
            <ShowButton />
        </Datagrid>
    </List>
);

export default Wallets;
