import React from 'react';
import PropTypes from 'prop-types';
import {TextInput} from 'react-admin';
import {useFormState} from 'react-final-form';

const ValidationInput = (props) => {
    const {
        validationKey,
        validationSecondKey,
        validationValue,
        validationSecondValue,
        style,
        min,
        max,
    } = props;
    const {values} = useFormState();

    if (values[validationKey] !== validationValue) {
        return null;
    }

    if (validationSecondKey && (values[validationSecondKey] !== validationSecondValue)) {
        return null;
    }

    return (
        <div style={style}>
            <TextInput {...props} />
            <div style={{fontSize: 10, marginTop: -25}}>
                {min ? `mínimo ${min} dígitos` : ''} {max ? `máximo ${max} dígitos` : ''}
            </div>
        </div>
    );
};

ValidationInput.propTypes = {
    label: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    validationKey: PropTypes.string.isRequired,
    validationSecondKey: PropTypes.string,
    validationSecondValue: PropTypes.string,
    validationValue: PropTypes.string,
    style: PropTypes.shape({}),
    min: PropTypes.string,
    max: PropTypes.string,
};

ValidationInput.defaultProps = {
    validationValue: '',
    validationSecondKey: '',
    validationSecondValue: '',
    style: {},
    min: '',
    max: '',
};

export default ValidationInput;
