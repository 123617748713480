import React, { useEffect, useState, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import Swal from 'sweetalert2';

import vitaService from '../services/vita';
import { isValidEmail } from '../utils';

const useVita = () => {
    const [user, setUser] = useState(null);
    const { values: { email = '' } } = useFormState();

    useEffect(() => {
        if (isValidEmail(email)) {
            (async () => {
                try {
                    const { vita_user } = await vitaService.getVitaEmail(email);
                    const { attributes } = vita_user;

                    if (attributes.status === 'banned' && !attributes.is_received) {
                        Swal.fire({
                            title: 'Usuario Vita baneado!',
                            text: 'El usuario al cual pretende enviar dinero se encuentra baneado, por lo cual no podrá realizar la transacción',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }

                    setUser(vita_user);
                } catch {
                    setUser(null);
                }
            })();
        }
    }, [email]);

    const message = useMemo(() => {
        if (email) {
            if (!(!!user)) {
                return 'El Correo no existe en Vita';
            }

            if (user && !user.attributes.is_received) {
                return 'No puede recibir';
            }
        }

        return '';
    }, [email, user]);

    const isEmailExist = useMemo(() => {
        return !!user;
    }, [user]);

    const isReceived = useMemo(() => {
        if (user) {
            return user.attributes.is_received;
        }

        return false;
    }, [user]);

    const countryIsoCode = useMemo(() => {
        if (user) {
            return user.attributes.country_iso_code;
        }

        return null;
    }, [user]);

    return {
        message,
        countryIsoCode,
        isEmailExist,
        isReceived,
    };
};

export default useVita;
