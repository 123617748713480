import {ENV, CREDENTIALS} from './env';

const {RECAPTCHA} = CREDENTIALS;

export const GOOGLE_RECAPTCHA = RECAPTCHA[ENV];

/*
login: 22af58c20428af65f6b868542aa6c2e5c99047dd

transkey: He6WghmXPUHQIot6Hf3HlQiYQmQ=

secret:
9a6187c0c57023ddba55653c46fde508d5fcbc75d576d3797dbfb1093b49c1ee59258cd0eabb0152595235ea2ca25cb8b541735a7bca5d575f750c5ebb5e2fc37874fbe0557b2f1dfeaa3b2d60fce4adb5ae4b6f79f2ec9833edbafdb758df7ad4c3a116a81107beadc42e6aa0a9d2508af689993438e17742f36348d1bc45db1fad97f5750c4c03611279472e2b0dba62ef012008795d13a16c7283e471c9a6c55d6e4040a17c2bf82bbf3293a8e4023f530246e03c6d471d4366af3f3ccc71f0855bc54358a2a808799b5cce78132e26f5b1315fa0bfad8a1b98f63db4cd52916deb52c17f2bb6899c7ac609a8da110dc9c6a9bdaf28ec2f30bfb8702b3c21f91c62807f091778d8ade848c0e48bbcb03e6cfed193f3839a1c86b926d9381e603a7426dd980587ea33f428
 */

