import * as React from "react";
import {Create, SimpleForm, TextInput} from 'react-admin';

const Wallet = (props) => (
    <Create
        {...props}
        title="Crear wallet"
    >
        <SimpleForm>
            <TextInput
                label="Token único"
                source="token"
            />
        </SimpleForm>
    </Create>
);

export default Wallet;
