import wallets from './wallets';
import transactions from './transactions';
import banks from './banks';
import requests from './requests';
import rules from './rules';

const resources = {
    wallets,
    transactions,
    banks,
    requests,
    rules,
};

export default resources;
