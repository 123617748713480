import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-google';

import {GOOGLE_RECAPTCHA} from '../../../config/constants';

export const useGoogleRecaptcha = () => {
    const [isValidRecaptcha, setValidRecaptcha] = useState(false);

    const validateRecaptcha = () => {
        setValidRecaptcha(true);
    };

    const rejectRecaptcha = () => {
        setValidRecaptcha(false);
    };

    return {
        isValidRecaptcha,
        validateRecaptcha,
        rejectRecaptcha,
    };
};

const HackToRecaptcha = (props) => {
    const {children} = props;
    const [reload, setReload] = useState(false);
    const [stop, setStop] = useState(false);

    useEffect(() => {
        let isRefresh = true;

        if (!stop && isRefresh) {
            if (!(global.grecaptcha === undefined || !global.grecaptcha.render)) {
                isRefresh = false;
                setStop(true);
            }
            setReload(!reload);
        }

        return () => {
            isRefresh = false;
            setStop(true);
        };
    }, [reload, stop]);

    if (global.grecaptcha && global.grecaptcha.render) {
        return children;
    }

    return null;
};

HackToRecaptcha.propTypes = {
    children: PropTypes.any,
};

HackToRecaptcha.defaultProps = {
    children: <></>,
};

const styles = {
    box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
    },
};

const GoogleReCaptcha = (props) => {
    const {validateRecaptcha, rejectRecaptcha, isLeft} = props;
    const captchaRef = useRef(null);

    const onloadCallback = () => {
        if (captchaRef.current) {
            captchaRef.current.reset();
        }
    };

    const verifyCallback = (token) => {
        if (token) {
            validateRecaptcha();
        } else {
            rejectRecaptcha();
        }
    };

    useEffect(() => {
        loadReCaptcha();
        onloadCallback();
    }, []);

    return (
        <div style={isLeft ? {} : styles.box}>
            <HackToRecaptcha>
                <ReCaptcha
                    {...props}
                    ref={captchaRef}
                    verifyCallback={verifyCallback}
                    onloadCallback={onloadCallback}
                />
            </HackToRecaptcha>
        </div>
    );
};

GoogleReCaptcha.propTypes = {
    size: PropTypes.string,
    render: PropTypes.string,
    sitekey: PropTypes.string,
    rejectRecaptcha: PropTypes.func,
    validateRecaptcha: PropTypes.func,
    expiredCallback: PropTypes.func,
    isLeft: PropTypes.bool,
};

GoogleReCaptcha.defaultProps = {
    size: 'normal',
    render: 'explicit',
    sitekey: GOOGLE_RECAPTCHA,
    rejectRecaptcha: () => {},
    validateRecaptcha: () => {},
    expiredCallback: () => {
        window.location.reload();
    },
    isLeft: false,
};

export default GoogleReCaptcha;
