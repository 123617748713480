import React from 'react';
import {Show, SimpleShowLayout, TextField, BooleanField, FunctionField} from 'react-admin';

const Request = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <BooleanField
                label="Exito"
                source="isSuccessful"
            />
            <TextField
                label="Endpoint"
                source="endpoint"
            />
            <TextField
                label="Url"
                source="url"
            />
            <TextField
                label="Método"
                source="method"
            />
            <FunctionField
                label="Cabeceras"
                render={record => JSON.stringify(record.headers)}
            />
            <FunctionField
                        label="Parametros"
                        render={record => JSON.stringify(record.params)}
                    />
                    <FunctionField
                        label="Cuerpo"
                        render={record => JSON.stringify(record.data)}
                    />
                    <FunctionField
                        label="Resultado"
                        render={record => JSON.stringify(record.result)}
                    />
        </SimpleShowLayout>
    </Show>
);

export default Request;
