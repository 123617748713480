import List from './List';
import Create from './Create';
import Show from './Show';

const resource = {
    name: 'wallets',
    list: List,
    create: Create,
    show: Show,
};

export default resource;
