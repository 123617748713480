import wallets from './wallets';
import transactions from './transactions';
import banks from './banks';
import requests from './requests';
import rules from './rules';
import credentials from '../config/credentials.json';

const resources = [
    wallets,
    transactions,
    banks,
    rules,
];

if (credentials.isDevelopment) {
    resources.push(requests);
}

export default resources;
