import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useFormState } from 'react-final-form';

import pricesService from '../services/prices';
import useInterval from './useInterval';
import useVita from './useVita';

const usePrices = () => {
    const {
        values: {
            wallet = '',
            country = '',
            currency = '',
            amount = 0,
            transactions_type = 'withdrawal',
        },
    } = useFormState();
    const { message, countryIsoCode } = useVita();
    const [prices, setPrices] = useState(null);

    const getPrices = async () => {
        try {
            console.log('Vamos por precios');
            const response = await pricesService.getPrices(wallet);
            setPrices(response);
        } catch { }
    };

    const delay = useMemo(() => {
        if (prices) {
            const { withdrawal = null } = prices[currency] || prices;
            if (withdrawal) {
                const { valid_until } = withdrawal['prices']['attributes'];

                return moment(valid_until)
                    .diff(moment().utc(0), 'milliseconds');
            }
        }

        return null;
    }, [prices, currency]);

    const receivePrices = useMemo(() => {
        if (prices && transactions_type) {
            if (transactions_type === 'withdrawal') {
                const { withdrawal = null } = prices[currency] || prices;
                if (withdrawal && withdrawal['prices']['attributes'][`${currency.toLowerCase()}_sell`]) {
                    const price = withdrawal['prices']['attributes'][`${currency.toLowerCase()}_sell`][country.toLowerCase()];
                    const fixedCost = withdrawal['prices']['attributes'][`fixed_cost${currency.toLowerCase() === 'usd' ? '_usd' : ''}`][country.toLowerCase()];

                    return {
                        price: Math.floor(price * 100) / 100,
                        fixedCost,
                        total: Math.floor((amount * price - fixedCost) * 100) / 100,
                    };
                }
            } else {
                if (countryIsoCode) {
                    const { vita_sent } = prices[currency] || prices;
                    if (vita_sent['prices']['attributes'][`${currency.toLowerCase()}_sell`]) {
                        const price = vita_sent['prices']['attributes'][`${currency.toLowerCase()}_sell`][countryIsoCode.toLowerCase()] || vita_sent['prices']['attributes'][`${currency.toLowerCase()}_sell`];
                        const fixedCost = vita_sent['prices']['attributes'][`fixed_cost${currency.toLowerCase() === 'usd' ? '_usd' : ''}`][countryIsoCode.toLowerCase()] || vita_sent['prices']['attributes'][`fixed_cost${currency.toLowerCase() === 'usd' ? '_usd' : ''}`];

                        return {
                            price: Math.floor(price * 100) / 100,
                            fixedCost,
                            total: Math.floor((amount * price - fixedCost) * 100) / 100,
                        };
                    }
                }
            }
        }

        return 0;
    }, [prices, amount, country, transactions_type, countryIsoCode, currency]);

    useInterval(getPrices, delay);

    useEffect(() => {
        let isFetch = true;

        if (isFetch && wallet) {
            getPrices();
            isFetch = false;
        }

        return () => {
            isFetch = false;
        };
    }, []);

    return {
        prices,
        message,
        receiveAmount: receivePrices['total'],
        price: receivePrices['price'],
        fixedCost: receivePrices['fixedCost'],
    };
};

export default usePrices;
