import React from 'react';
import {useFormState} from 'react-final-form';

const WithdrawalTitle = (props) => {
    const {values} = useFormState();

    if (!values['transactions_type'] || values['transactions_type'] !== 'withdrawal' || !props.rule) {
        return null;
    }

    return (
        <div className="MuiFormLabel-root" style={{paddingLeft: 10}}>
            Formulario de retiros
        </div>
    );
};

export default WithdrawalTitle;