import Business from 'vita-business-node';

const getPrices = (wallet = '') => {
    return wallet ?
        Business.wallets(wallet).updatePrices() :
        Business.prices().get();
};

const service = {
    getPrices,
};

export default service;
