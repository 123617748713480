import React from 'react';
import PropTypes from 'prop-types';
import {TextInput} from 'react-admin';
import {useFormState} from 'react-final-form';

const VitaEmail = (props) => {
    const {validationKey, validationValue, style} = props;
    const {values} = useFormState();

    if (values[validationKey] !== validationValue) {
        return null;
    }

    return (
        <div style={{...styles.box, ...style}}>
            <TextInput
                {...props}
                label="Correo electronico"
                source="email"
            />
        </div>
    );
};

VitaEmail.propTypes = {
    validationKey: PropTypes.string.isRequired,
    validationValue: PropTypes.string,
    style: PropTypes.shape({}),
};

VitaEmail.defaultProps = {
    validationValue: '',
    style: {},
};

const styles = {
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
};

export default VitaEmail;
