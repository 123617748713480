import {useState, useMemo, useCallback} from 'react';
import {useLogin as useLoginAdmin} from 'react-admin';
import Business from 'vita-business-node';
import {useGoogleRecaptcha} from '../components/commons/GoogleReCaptcha';

import credentials from '../config/credentials.json';

const useLogin = (props) => {
    const {history: {replace = () => {}}} = props;
    const login = useLoginAdmin();
    const recaptchaHook = useGoogleRecaptcha();
    const {isValidRecaptcha} = recaptchaHook;
    const [xLogin, setXLogin] = useState('');
    const [xTransKey, setXTransKey] = useState('');
    const [secret, setSecret] = useState('');

    const isValidForm = useMemo(() => {
        return xLogin && xTransKey && secret && isValidRecaptcha;
    }, [xLogin, xTransKey, secret, isValidRecaptcha]);

    const changeXLogin = (event) => {
        setXLogin(event .target.value);
    };

    const changeXTransKey = (event) => {
        setXTransKey(event.target.value);
    };

    const changeSecret = (event) => {
        setSecret(event.target.value);
    };

    const onSubmit = useCallback(async () => {
        if (isValidRecaptcha) {
            Business.config({
                ...credentials,
                "X_Login": xLogin,
                "X_Trans_Key": xTransKey,
                "secret": secret,
            });

            try {
                await login();
            } catch {}

            replace('/wallets');
        }
    }, [xLogin, xTransKey, secret, isValidRecaptcha]);

    return {
        xLogin,
        xTransKey,
        secret,
        isValidForm,
        onSubmit,
        recaptchaHook,
        changeXLogin,
        changeXTransKey,
        changeSecret,
    };
};

export default useLogin;
