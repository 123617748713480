import {useState, useEffect} from 'react';

import walletService from '../services/wallets';

const useWallets = () => {
    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                const response = await walletService.getWallets();
                setWallets(response.data.map(item => ({
                    id: item.uuid,
                    name: item.uuid,
                })));

                isFetch = false;
            } catch {}
        })();

        return () => {
            isFetch = false;
        };
    }, []);

    return {
        wallets,
    };
};

export default useWallets;
