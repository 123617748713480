import React from 'react';
import PropTypes from 'prop-types';
import {SelectInput} from 'react-admin';
import {useFormState} from 'react-final-form';

import useCountries from '../../../hooks/useCountries';

const CountrySelect = (props) => {
    const {isValidation, validationKey, validationValue, style} = props;
    const {values} = useFormState();
    const {countries} = useCountries();

    if (isValidation && values[validationKey] !== validationValue) {
        return null;
    }

    return (
        <div style={style}>
            <SelectInput
                {...props}
                label="País"
                source="country"
                choices={countries}
            />
        </div>
    );
};

CountrySelect.propTypes = {
    isValidation: PropTypes.bool,
    style: PropTypes.shape({}),
    validationKey: PropTypes.string,
    validationValue: PropTypes.string,
};

CountrySelect.defaultProps = {
    isValidation: false,
    style: {},
    validationKey: '',
    validationValue: '',
};

export default CountrySelect;
