import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import WalletSelect from '../../components/commons/WalletSelect';
import ValidationInput from '../../components/commons/ValidationInput';
import CountrySelect from '../../components/commons/CountrySelect';
import ValidationSelect from '../../components/commons/ValidationSelect';
import ReceiveAmount from '../../components/commons/ReceiveAmount';
import VitaEmail from '../../components/commons/VitaEmail';
import WhenValidation from '../../components/commons/WhenValidation';
import Rules from '../../components/commons/Rules';
import WithdrawalTitle from '../../components/commons/WithdrawalTitle';
import { TRANSACTION_TYPE, CURRENCIES } from '../../utils';
import vitaService from '../../services/vita';
import Swal from 'sweetalert2';

const VerifyBeneficiary = () => {
    const { values = {} } = useFormState();

    useEffect(() => {
        let isFetch = true;

        if (
            isFetch &&
            values["country"] &&
            values["beneficiary_document_number"] &&
            values["beneficiary_document_type"]
        ) {
            (async () => {
                try {
                    const payload = {
                        document_number: values["beneficiary_document_number"],
                        country_iso_code: values["country"],
                    }

                    if (values["beneficiary_type"]) {
                        payload['beneficiary_type'] = values["beneficiary_type"]
                    }

                    if (!((values["beneficiary_type"] || "").toLowerCase() === 'corporate')) {
                        payload['document_type'] = values["beneficiary_document_type"]
                    }

                    const { beneficiary: { attributes } } = await vitaService.getBeneficiary(payload)

                    if (attributes.status === 'banned' && !attributes.is_received) {
                        Swal.fire({
                            title: 'Beneficiario baneado!',
                            text: 'El beneficiario al cual pretende enviar dinero se encuentra baneado, por lo cual no podrá realizar la transacción',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }
                } catch { }
            })()
        }

        return () => {
            isFetch = false;
        }
    }, [
        values["country"],
        values["beneficiary_document_number"],
        values["beneficiary_document_type"],
        values["beneficiary_type"],
    ])

    return null;
}

const Transaction = (props) => (
    <Create {...props} title="Crear transacción">
        <SimpleForm >
            <VerifyBeneficiary />
            <Rules
                changeRule={props.setRule}
                changeCurrencyRecipient={props.setCurrencyRecipient}
            />
            <Box width={'100%'} display={{ xs: 'flex', sm: 'flex' }}>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput
                        label="Tipo"
                        source="transactions_type"
                        choices={TRANSACTION_TYPE}
                    />
                </Box>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <WalletSelect />
                </Box>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput
                        label="Moneda"
                        source="currency"
                        choices={CURRENCIES}
                    />
                </Box>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <CountrySelect
                        isValidation
                        validationKey="transactions_type"
                        validationValue="withdrawal"
                    />
                </Box>
            </Box>
            <Box width={'100%'} display={{ xs: 'flex', sm: 'flex' }}>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        label="Orden"
                        source="order"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        label="Monto"
                        source="amount"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: '0.5em' }}>
                    <ReceiveAmount
                        label="Monto a recibir"
                        validationKey="transactions_type"
                        validationValues={["withdrawal", "vita_sent"]}
                        currencyRecipient={props.currencyRecipient}
                    />
                </Box>
            </Box>
            <Box width={'100%'} display={{ xs: 'flex', sm: 'flex' }}>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <VitaEmail
                        validationKey="transactions_type"
                        validationValue="vita_sent"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <ValidationInput
                        label="Url de cancelación"
                        source="url_cancel"
                        validationKey="transactions_type"
                        validationValue="recharge"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <ValidationInput
                        label="Url de completado"
                        source="url_complete"
                        validationKey="transactions_type"
                        validationValue="recharge"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <ValidationInput
                        label="Url de notificación"
                        source="url_notify"
                        validationKey="transactions_type"
                        validationValue="withdrawal"
                    />
                </Box>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <WalletSelect
                        isReceived
                        isValidation
                        validationKey="transactions_type"
                        validationValue="sent"
                    />
                </Box>
            </Box>
            <Box width={'100%'} pt={4} pb={3} display={{ xs: 'flex', sm: 'flex' }}>
                <Box mr={{ xs: 0, sm: 0 }}>
                    <WithdrawalTitle rule={props.rule} />
                </Box>
            </Box>
            <div
                style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                }}
            >
                {
                    props.rule.map((field) => {
                        if (field.type === 'select') {
                            return (
                                <WhenValidation
                                    fieldItem={field}
                                    rule={props.rule}
                                >
                                    <ValidationSelect
                                        {...props}
                                        label={field.name}
                                        source={field.key}
                                        choices={field.options.map((option) => {
                                            if (option.hasOwnProperty('visible')) {
                                                return {
                                                    id: option.value,
                                                    name: option.label,
                                                    visible: option.visible,
                                                }
                                            } else {
                                                return {
                                                    id: option.value,
                                                    name: option.label,
                                                }
                                            }
                                        })}
                                        validationKey="transactions_type"
                                        validationValue="withdrawal"
                                        min={field.min}
                                        max={field.max}
                                    />
                                </WhenValidation>
                            );
                        }

                        return (
                            <WhenValidation
                                fieldItem={field}
                                rule={props.rule}
                            >
                                <ValidationInput
                                    label={field.name}
                                    source={field.key}
                                    validationKey="transactions_type"
                                    validationValue="withdrawal"
                                    min={field.min}
                                    max={field.max}
                                />
                            </WhenValidation>
                        );
                    })
                }
            </div>
        </SimpleForm>
    </Create>
);

const Hoc = (props) => {
    const [rule, setRule] = useState([]);
    const [currencyRecipient, setCurrencyRecipient] = useState('');

    return (
        <Transaction
            {...props}
            rule={rule}
            setRule={setRule}
            currencyRecipient={currencyRecipient}
            setCurrencyRecipient={setCurrencyRecipient}
        />
    );
};

export default Hoc;