import Business from 'vita-business-node';

const getVitaEmail = (email) => {
    return Business.vitaProvider.getVitaEmail(email);
};

const getBeneficiary = (data = {}) => {
    return Business.vitaProvider.getBeneficiary(data);
};

const service = {
    getVitaEmail,
    getBeneficiary,
};

export default service;
