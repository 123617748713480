import { HttpError } from 'react-admin';

export const TRANSACTION_STATUS = {
    completed: 'Completado',
    denied: 'Rechazado',
    started: 'Iniciado',
    pending: 'Pendiente',
    processed: 'Procesado',
};

export const TRANSACTION_CATEGORY = {
    //recharge: 'Recargas',
    //purchase: 'Compras',
    //sent: 'Envíos',
    withdrawal: 'Retiros',
    vita_sent: 'Envíos a Vita Wallet'
};

export const COUNTRIES_ISO_CODE = [
    { id: 'cl', name: 'Chile' },
    { id: 'co', name: 'Colombia' },
    { id: 'us', name: 'Estados unidos' },
    { id: 'mx', name: 'México' },
    { id: 've', name: 'Venezuela' },
];

export const TRANSACTION_TYPE = [
    //{ id: 'recharge', name: 'Recargas' },
    //{ id: 'purchase', name: 'Compras' },
    //{ id: 'sent', name: 'Envíos' },
    { id: 'withdrawal', name: 'Retiros' },
    { id: 'vita_sent', name: 'Envíos a vita' },
];

export const CURRENCIES = [
    { id: 'clp', name: 'Peso chileno' },
    { id: 'usd', name: 'Dólar estadounidense' },
    { id: 'cop', name: 'Peso Colombiano' },
    { id: 'mxn', name: 'Peso Mexicano' },
    { id: 'ars', name: 'Peso Argentino' },
    { id: 'btc', name: 'BTC' },
    { id: 'usdt', name: 'USDT' },
    { id: 'usdc', name: 'USDC' },
];

export const BANK_ACCOUNT_TYPE = [
    { id: 'CA', name: 'Cuenta de ahorros' },
    { id: 'CC', name: 'Cuenta corriente' },
];

export const DOCUMENT_TYPE_CHILE = [
    { id: 'RUT', name: 'Rol único tributario' },
    { id: 'PASS', name: 'Pasaporte' },
    { id: 'CI', name: 'Cédula de identidad' },
];

export const DOCUMENT_TYPE_COLOMBIA = [
    { id: 'PASS', name: 'Pasaporte' },
    { id: 'CC', name: 'Cédula de ciudadanía' },
    { id: 'NIT', name: 'Numero de identificación tributaria' },
    { id: 'CE', name: 'Carnet de extranjería' },
];

export const DOCUMENT_TYPE_MEXICO = [
    { id: 'PASS', name: 'Pasaporte' },
    { id: 'DNI', name: 'Documento nacional de identidad' },
    { id: 'CURP', name: 'Clave única de registro de población' },
    { id: 'CURPE', name: 'Clave única de registro de población para empresas' },
    { id: 'ID', name: 'Documento de identificación de empresas' },
];

export const DOCUMENT_TYPE_VENEZUELA = [
    { id: 'PASS', name: 'Pasaporte' },
    { id: 'CC', name: 'Cédula de ciudadanía' },
    { id: 'CE', name: 'Carnet de extranjería' },
    { id: 'RIF', name: 'Registro de información fiscal' },
];

export const DOCUMENT_TYPE_COUNTRY = {
    co: DOCUMENT_TYPE_COLOMBIA,
    cl: DOCUMENT_TYPE_CHILE,
    ve: DOCUMENT_TYPE_VENEZUELA,
    mx: DOCUMENT_TYPE_MEXICO,
};

export const BANK_ACCOUNT_TYPE_COUNTRY = {
    co: BANK_ACCOUNT_TYPE,
    cl: BANK_ACCOUNT_TYPE,
    ve: BANK_ACCOUNT_TYPE,
    pe: BANK_ACCOUNT_TYPE,
    mx: [{ id: 'CLABE', name: 'CLABE' },]
};

export const PURPOSE_CODE = [
    { id: 'EPFAMT', name: 'Mantenimiento familiar' },
    { id: 'ISMDCS', name: 'Pago por servicios de atención medica' },
    { id: 'ISSTDY', name: 'Pago de costos de estudio/matricula' },
    { id: 'ISCHAR', name: 'Pago por razones de caridad' },
    { id: 'EPPROP', name: 'Pago por compra de propiedad' },
    { id: 'EPSHAR', name: 'Pago de acciones' },
    { id: 'EPIVST', name: 'Pago de una inversión' },
    { id: 'ISUBIL', name: 'Pago al proveedor de servicios públicos comunes' },
    { id: 'ISTAXS', name: 'Pago de impuestos' },
    { id: 'EPTOUR', name: 'Turismo' },
    { id: 'ISSAVG', name: 'Pago a cuenta de ahorro/jubilación' },
    { id: 'ISPENS', name: 'Pago de pensión' },
    { id: 'ISPAYR', name: 'Pago de nomina' },
    { id: 'ISGDDS', name: 'Compra venta de bienes' },
    { id: 'ISSUPP', name: 'Pago del proveedor' },
    { id: 'EPREMT', name: 'Remesas' },
    { id: 'ISSCVE', name: 'Compra venta de servicios' },
];

export const isValidEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};

export const getRequestsFromStorage = () => {
    let fromStorage = null;
    let requests = { req: [] };

    try {
        fromStorage = global.localStorage.getItem('REQUESTS');
    } finally {
        if (fromStorage) {
            requests = JSON.parse(fromStorage);
        }
    }

    return requests.req;
};

export const deleteRequestFromStorage = (ids = []) => {
    let fromStorage = null;
    let requests = { req: [] };

    try {
        fromStorage = global.localStorage.getItem('REQUESTS');
    } finally {
        if (fromStorage) {
            requests = JSON.parse(fromStorage);
        }

        const aux = [];
        requests.req.map((request) => {
            if (!ids.includes(request.id)) {
                aux.push(request)
            }

            return null;
        });

        requests.req = aux;
        global.localStorage.setItem('REQUESTS', JSON.stringify(requests));
    }

    return requests.req;
};

export const returnError = (error) => {
    console.log('Error::::', error);
    error = JSON.parse(error.message);
    return Promise.reject(
        new HttpError(error.message, error.code)
    );
};
