import React from 'react';
import {createBrowserHistory as createHistory} from 'history';
import {Admin, Resource} from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';

import dataProvider from '../providers/rest';
import authProvider from '../providers/auth';
import resources from '../resources';
import Login from '../scenes/auth/login';

const history = createHistory();

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#ffffff',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#292929',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffffff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        typography: {
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            fontSize: 18,
        },
    },
});

const Administrator = () => {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            history={history}
            loginPage={Login}
            theme={theme}
        >
            {resources.map(resource => (<Resource key={resource.name} {...resource} />))}
        </Admin>
    );
};

export default Administrator;
