import React from 'react';
import PropTypes from 'prop-types';
import {SelectInput} from 'react-admin';
import {useFormState} from 'react-final-form';

import useWallets from '../../../hooks/useWallets';

const WalletSelect = (props) => {
    const {isReceived, isValidation, validationKey, validationValue, style} = props;
    const {wallets} = useWallets();
    const {values} = useFormState();

    if (isValidation && values[validationKey] !== validationValue) {
        return null;
    }

    return (
        <div style={style}>
            <SelectInput
                label={isReceived ? "Recibe" : "Wallet"}
                source={isReceived ? "wallet_recipient" : "wallet"}
                choices={wallets}
            />
        </div>
    );
};

WalletSelect.propTypes = {
    isReceived: PropTypes.bool,
    isValidation: PropTypes.bool,
    validationKey: PropTypes.string,
    validationValue: PropTypes.string,
    style: PropTypes.shape({}),
};

WalletSelect.defaultProps = {
    isReceived: false,
    isValidation: false,
    validationKey: '',
    validationValue: '',
    style: {},
};

export default WalletSelect;
