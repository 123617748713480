import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import GoogleReCaptcha from '../../components/commons/GoogleReCaptcha';

import useLogin from '../../hooks/useLogin';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    separator: {
        marginLeft: 20,
        marginTop: 20,
    },
};

const Form = (props) => (
    <div>
        <div style={styles.container}>
            <img src="https://vitawallet.io/assets/vita_business_logo_footer.png" height="120" />
        </div>
        <div style={styles.container}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="outlined-basic"
                        label="X-Login"
                        variant="outlined"
                        value={props.xLogin}
                        onChange={props.changeXLogin}
                    />
                    <TextField
                        id="outlined-basic"
                        label="X-Trans-Key"
                        variant="outlined"
                        value={props.xTransKey}
                        onChange={props.changeXTransKey}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Secret"
                        variant="outlined"
                        value={props.secret}
                        onChange={props.changeSecret}
                    />
                </div>
            </Box>
            <Button
                variant="contained"
                onClick={props.onSubmit}
                disabled={!props.isValidForm}
                style={styles.separator}
            >
                Iniciar sesión
            </Button>
        </div>
        <GoogleReCaptcha {...props.recaptchaHook} />
    </div>
);

Form.propTypes = {
    xLogin: PropTypes.string,
    xTransKey: PropTypes.string,
    secret: PropTypes.string,
    isValidForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    changeXLogin: PropTypes.func,
    changeXTransKey: PropTypes.func,
    changeSecret: PropTypes.func,
};

Form.defaultProps = {
    xLogin: '',
    xTransKey: '',
    secret: '',
    isValidForm: false,
    onSubmit: () => {},
    changeXLogin: () => {},
    changeXTransKey: () => {},
    changeSecret: () => {},
};

const Login = (props) => {
    const hook = useLogin(props);
    return <Form {...hook} />;
};

Login.propTypes = {
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
    }).isRequired,
};

export default Login;
