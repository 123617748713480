import Business from 'vita-business-node';

const getWallets = () => {
    return Business.wallets().get();
};

const service = {
    getWallets,
};

export default service;
