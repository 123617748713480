import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useFormState} from 'react-final-form';

const WhenValidation = (props) => {
    const {
        rule, 
        children,
        fieldItem,
    } = props;

    const {values} = useFormState();

    const isValidate = useMemo(() => {
        if (!rule) return false;
      
        const field = rule.find((field) => fieldItem === field) || null;
        return field && field.when && values[field.when.key] === field.when.value;
      }, [rule, fieldItem, values]);

    if (!!fieldItem.hasOwnProperty('when') && !isValidate) {
        return null;
    }

    return <>{children}</>;
};

WhenValidation.propTypes = {
    rule: PropTypes.array, 
    children: PropTypes.any,
    fieldItem: PropTypes.shape({}),
};

WhenValidation.defaultProps = {
    rule: [], 
    children: <></>,
    fieldItem: {},
};

export default WhenValidation;