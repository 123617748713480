import React from 'react';
import {JsonField} from 'react-admin-json-view';
import {Show, SimpleShowLayout, TextField, DateField, FunctionField, UrlField} from 'react-admin';

import {TRANSACTION_CATEGORY, TRANSACTION_STATUS} from '../../utils';

const Transaction = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id Vita"
                source="vita_transaction_id"
            />
            <DateField
                label="Fecha"
                source="created_at"
            />
            <TextField
                label="Wallet que envía"
                source="sender_wallet.uuid"
            />
            <TextField
                label="Wallet que recibe"
                source="recipient_wallet.uuid"
            />
            <TextField
                label="Moneda"
                source="currency"
            />
            <FunctionField
                label="Categoría"
                render={record => TRANSACTION_CATEGORY[record.category]}
            />
            <TextField
                label="Monto"
                source="amount"
            />
            <TextField
                label="Total"
                source="total"
            />
            <TextField
                label="Comisión"
                source="fee_value"
            />
            <TextField
                label="Comisión total"
                source="total_fee"
            />
            <FunctionField
                label="Estado"
                render={record => TRANSACTION_STATUS[record.status]}
            />
            <TextField
                label="Mensaje Estado"
                source='included.withdrawal.reject_motive'
            />
            <TextField
                label="Número de orden"
                source="order"
            />
            <UrlField source="tracking_url" />
            <JsonField
                source="included"
                jsonString={false}
                reactJsonOptions={{
                    name: 'Valor incluido',
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                    theme: "ocean",
                }}
            />
        </SimpleShowLayout>
    </Show>
);

export default Transaction;
