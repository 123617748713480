import {useEffect, useState} from 'react';

import rulesService from '../services/rules';

const useCountries = () => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                const response = await rulesService.getRules();
                setCountries(
                    Object
                        .keys(response.rules)
                        .map((key) => ({
                            id: key.toUpperCase(),
                            name: response.rules[key].name.toUpperCase(),
                        }))
                );
            } catch {}
        })();

        return () => {
            isFetch = false;
        };
    }, []);

    return {
        countries,
    };
};

export default useCountries;