import React, {useEffect} from 'react';
import Business from 'vita-business-node';
import {useFormState} from 'react-final-form';

const Rules = (props) => {
    const {changeRule, changeCurrencyRecipient} = props;
    const {values} = useFormState();

    useEffect(() => {
        if (
            values.country &&
            values.transactions_type &&
            values.transactions_type === 'withdrawal'
        ) {
            (async () => {
                try {
                    const response = await Business
                        .rulesProvider
                        .getWithdrawalRules();
                    changeRule(response.rules[values.country.toLowerCase()].fields);
                    changeCurrencyRecipient(response.rules[values.country.toLowerCase()].currency_iso_code);
                } catch {}
            })();
        }
    }, [values.country, values.transactions_type]);

    return null;
};

export default Rules;