import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {SelectInput} from 'react-admin';
import {useFormState} from 'react-final-form';

const ValidationSelect = (props) => {
    const {validationKey, validationValue, style, min, max} = props;
    const {values} = useFormState();

    const choices = useMemo(() => props.choices.filter((item) =>
    {
        if (!item.hasOwnProperty('visible')){
            return true;
        }
        else{
            if (item.visible){
                const { visible: { key, value }} = item;
                let isFind = false;
                value.find(i => values[key] === i && ( isFind = true ));
                return isFind;
            };
            return true;
        }
    }
    ), [props.choices, values])

    if (values[validationKey] !== validationValue) return null;

    return (
        <div style={style}>
            <SelectInput {...props} choices={choices}  />
            <div style={{fontSize: 10, marginTop: -25}}>
                {min ? `mínimo ${min} dígitos` : ''} {max ? `máximo ${max} dígitos` : ''}
            </div>
        </div>
    );
};

ValidationSelect.propTypes = {
    label: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    validationKey: PropTypes.string.isRequired,
    validationValue: PropTypes.string,
    style: PropTypes.shape({}),
    min: PropTypes.string,
    max: PropTypes.string,
};

ValidationSelect.defaultProps = {
    validationValue: '',
    style: {},
    min: '',
    max: '',
};

export default ValidationSelect;