import React from 'react';
import {List, Datagrid, TextField, SelectInput} from 'react-admin';

import useCountries from "../../hooks/useCountries";

const Countries = (props) => {
    const {countries} = useCountries();
    return (
        <SelectInput
            {...props}
            choices={countries}
        />
    );
};

const filters = [
    <Countries
        alwaysOn
        label="Código ISO"
        source="iso_code"
    />,
];

const Banks = (props) => (
    <List
        {...props}
        filters={filters}
        title="Lista de bancos"
    >
        <Datagrid>
            <TextField source="bank_code" />
            <TextField source="name" />
            <TextField source="iso_code" />
            <TextField source="length_of_checking_account_number" />
            <TextField source="length_of_savings_account_number" />
        </Datagrid>
    </List>
);

export default Banks;
