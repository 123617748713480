import Business from 'vita-business-node';

import {returnError} from '../../utils';

const getList = async (params) => {
    try {
        const {pagination: {page, perPage}} = params;
        let response = await Business.wallets().get({
            page,
            count: perPage,
        });

        const wallets = response.data.map(wallet => ({
            ...wallet,
            id: wallet.uuid,
        }));

        return Promise.resolve({
            data: wallets,
            total: response.total,
        });
    } catch (error) {
        return returnError(error);
    }
};

const getOne = async (params) => {
    try {
        const {id} = params;
        let wallet = await Business.wallets(id).get();

        return Promise.resolve({data: {
            ...wallet,
            id: wallet.uuid
        }});
    } catch (error) {
        return returnError(error);
    }
};

const create = async (params) => {
    try {
        const {data: {token}} = params;
        let wallet = await Business.wallets().create(token);

        wallet = {
            ...wallet,
            id: wallet.uuid,
        };

        return Promise.resolve({data: wallet});
    } catch (error) {
        return returnError(error);
    }
};

const dataProvider = {
    getList,
    getOne,
    create,
};

export default dataProvider;
