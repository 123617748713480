import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, BooleanField } from 'react-admin';

const Wallet = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField
                label="Uuid"
                source="uuid"
            />
            <DateField
                label="Fecha"
                source="created_at"
            />
            <TextField
                label="Token"
                source="token"
            />
            <TextField
                label="Balance CLP"
                source="balances.clp"
            />
            <TextField
                label="Balance USD"
                source="balances.usd"
            />
            <TextField
                label="Balance COP"
                source="balances.cop"
            />
            <TextField
                label="Balance ARS"
                source="balances.ars"
            />
            <TextField
                label="Balance MXN"
                source="balances.mxn"
            />
            <TextField
                label="Balance BTC"
                source="balances.btc"
            />
            <TextField
                label="Balance USDT"
                source="balances.usdt"
            />
            <TextField
                label="Balance USDC"
                source="balances.usdc"
            />
            <BooleanField
                label="Maestra"
                source="is_master"
            />
        </SimpleShowLayout>
    </Show>
);

export default Wallet;
