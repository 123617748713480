import Business from 'vita-business-node';

import {returnError} from '../../utils';

const getList = async (params) => {
    try {
        const {pagination, filter} = params;
        const {page, perPage} = pagination;

        let response = await Business.banks(
            filter['iso_code'] ? filter['iso_code'] : 'cl'
        ).get({
            page,
            count: perPage,
        });

        const banks = response.data.map(bank => ({
            ...bank,
            id: bank.bank_code,
        }));

        return Promise.resolve({
            data: banks,
            total: banks.length,
        });
    } catch (error) {
        return returnError(error);
    }
};

const dataProvider = {
    getList,
};

export default dataProvider;
