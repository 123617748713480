import Business from 'vita-business-node';

const authProvider = {
    login: () => {
        return Business.isCredentials() ? Promise.resolve() : Promise.reject();
    },
    checkAuth: () => {
        return Business.isCredentials() ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        return Business.isCredentials() ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        Business.config({});
        return Promise.resolve('/login');
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            Business.config({});
            return Promise.reject('/login');
        }

        return Promise.resolve();
    },
};

export default authProvider;
