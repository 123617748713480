import {getRequestsFromStorage, deleteRequestFromStorage} from '../../utils';

const getList = () => {
    const data = getRequestsFromStorage();

    return Promise.resolve({
        data,
        total: data.length,
    });
};

const deleteMany = (params) => {
    const {ids} = params;
    const data = deleteRequestFromStorage(ids);

    return Promise.resolve({
        data,
        total: data.length,
    });
};

const dataProvider = {
    getList,
    deleteMany,
};

export default dataProvider;
