import Business from 'vita-business-node';

import {returnError} from '../../utils';

const getList = async () => {
    try {
        let response = await Business
            .rulesProvider
            .getWithdrawalRules();

        const rules = Object
            .keys(response.rules)
            .map((key) => ({
                id: key.toUpperCase(),
                fields: response.rules[key].fields,
            }));

        return Promise.resolve({
            data: rules,
            total: rules.length,
        });
    } catch (error) {
        return returnError(error);
    }
};

const dataProvider = {
    getList,
};

export default dataProvider;