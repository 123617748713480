import React from 'react';
import Business from 'vita-business-node';
import {List, Datagrid, TextField, FunctionField, BooleanField, ShowButton} from 'react-admin';

const Requests = (props) => {
    if (Business.isDevelopment()) {
        return (
            <List
                {...props}
                title="Lista de solicitudes a la api"
            >
                <Datagrid>
                    <BooleanField
                        label="Exito"
                        source="isSuccessful"
                    />
                    <TextField
                        label="Endpoint"
                        source="endpoint"
                    />
                    <TextField
                        label="Url"
                        source="url"
                    />
                    <TextField
                        label="Método"
                        source="method"
                    />
                    <FunctionField
                        label="Cabeceras"
                        render={record => JSON.stringify(record.headers)}
                    />
                    <ShowButton />
                </Datagrid>
            </List>
        );
    }

    return null;
};

export default Requests;
