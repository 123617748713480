import resources from '../resources';

const getList = (resource, params) => {
    return resources[resource].getList(params);
};

const getOne = (resource, params) => {
    return resources[resource].getOne(params);
};

const create = (resource, params) => {
    return resources[resource].create(params);
};

const deleteMany = (resource, params) => {
    return resources[resource].deleteMany(params);
};

const dataProvider = {
    getList,
    getOne,
    create,
    deleteMany,
};

export default dataProvider;
