import React from 'react';
import {List, Datagrid, TextField, FunctionField, DateField, ShowButton, TextInput} from 'react-admin';

import {
    TRANSACTION_STATUS,
    TRANSACTION_CATEGORY
} from '../../utils';

const Transactions = (props) => (
    <List
        {...props}
        title="Lista de transacciones"
        filters={[
            <TextInput 
                label="Nùmero de orden" 
                source="order" 
                alwaysOn
            />,
        ]}
    >
        <Datagrid>
            <TextField source="id" />
            <DateField source="created_at" />
            <TextField
                label="Nùmero de orden"
                source="order"
            />
            <TextField
                label="Wallet que envía"
                source="sender_wallet.uuid"
            />
            <TextField
                label="Wallet que recibe"
                source="recipient_wallet.uuid"
            />
            <TextField
                label="Email del destinatario"
                source="recipient_email"
            />
            <TextField
                label="Moneda"
                source="currency"
            />
            <FunctionField
                label="Categoría"
                render={record => TRANSACTION_CATEGORY[record.category]}
            />
            <TextField
                label="Monto"
                source="amount"
            />
            <TextField
                label="Total"
                source="total"
            />
            <TextField
                label="Comisión"
                source="fee_value"
            />
            <TextField
                label="Comisión total"
                source="total_fee"
            />
            <FunctionField
                label="Estado"
                render={record => TRANSACTION_STATUS[record.status]}
            />
            <ShowButton />
        </Datagrid>
    </List>
);

export default Transactions;
