import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {useFormState} from 'react-final-form';

import usePrices from '../../../hooks/usePrices';

const ReceiveAmount = (props) => {
    const {style, currencyRecipient} = props;
    const {values} = useFormState();
    const {prices, receiveAmount, message, price, fixedCost} = usePrices();

    if (!prices || !values['amount'] || receiveAmount === 0) {
        return null;
    }

    if (values['transactions_type'] === 'withdrawal' && !values['country']) {
        return null;
    }

    return (
        <div className="MuiFormLabel-root" style={{...styles.box, ...style}}>
            {message}
            <TextField
                disabled
                label="Monto a recibir"
                value={receiveAmount}
            />
            <div style={{paddingTop: 10}}>
                Precio: {price}, Costo fijo: {fixedCost} {currencyRecipient}
            </div>
        </div>
    );
};

ReceiveAmount.propTypes = {
    label: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
};

ReceiveAmount.defaultProps = {
    style: {},
};

const styles = {
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
};

const Hoc = (props) => {
    const {validationKey, validationValue, validationValues} = props;
    const {values} = useFormState();

    if (
        (validationValue && values[validationKey] !== validationValue) ||
        !values['wallet']
    ) {
        return null;
    }

    if (
        (validationValues.length > 0 && !validationValues.includes(values[validationKey])) ||
        !values['wallet']
    ) {
        return null;
    }

    return <ReceiveAmount {...props} />;
};

Hoc.propTypes = {
    validationKey: PropTypes.string.isRequired,
    validationValue: PropTypes.string,
    validationValues: PropTypes.array,
};

Hoc.defaultProps = {
    validationValue: '',
    validationValues: [],
};

export default Hoc;
